export const EURO_COUNTRIES = [
  'GBR',
  'AUT',
  'BEL',
  'DEU',
  'CYP',
  'ITA',
  'GRC',
  'FRA',
  'ESP',
  'NLD',
  'POL',
  'CZE',
  'IRL',
  'LVA',
  'LTU',
  'LUX',
  'MLT',
  'PRT',
  'SVK',
  'SVN',
  'FIN',
  'EST',
  'HUN',
  'DNK',
  'HRV',
  'SWE',
  'LIE',
  'NOR',
  'ISL'
];
