export const LANGUAGE = {
  RUSSIA: 'ru'
};

export const LANGUAGES_TO_FLAG_IDS = {
  ru: 'russia',
  en: 'britain',
  de: 'germany'
} as const;

export const LANGUAGES_TO_COUNTRY_IDS = {
  ru: 'RUS',
  en: 'ENG',
  de: 'GER'
} as const;

export const SUPPORTED_LOCALES = ['ru', 'en'];
