export const MARKET_LINKS = {
  GOOGLE_PLAY: {
    en: 'http://app.appsflyer.com/ru.tsk.ftc.bender.qpay/?pid=koronapay-eu-en&c=direct-site&af_channel=site',
    ru: 'http://app.appsflyer.com/ru.tsk.ftc.bender.qpay/?pid=koronapay-eu-ru&c=direct-site&af_channel=site'
  },
  APP_STORE: {
    en: 'http://app.appsflyer.com/1117334497/?pid=koronapay-eu-en&c=direct-site&af_channel=site',
    ru: 'http://app.appsflyer.com/1117334497/?pid=koronapay-eu-ru&c=direct-site&af_channel=site'
  }
} as const;

export const OPERATING_SYSTEM = {
  ANDROID: 'android',
  IOS: 'ios'
} as const;
