export const LOCATIONS = {
  ROOT: '/',
  TRANSFERS_EUROPE: '/transfers/europe/',
  TRANSFERS_REDIRECT: '/transfers/',
  BLOG: '/blog/',
  PROMO: '/blog/kak-zarabotat-300-evro-s-korona-pay-1/',
  CHECK_STATUS: '/check-status/',
  AGENTS: '/agents/',
  ABOUT: '/about/',
  HELP: '/help/',
  CONTACTS: '/contacts/'
};

export const NAV_MENU_LINKS = [
  { path: LOCATIONS.CHECK_STATUS, title: 'header_nav_transferStatus' },
  { path: LOCATIONS.AGENTS, title: 'header_nav_agentLocation' },
  { path: LOCATIONS.ABOUT, title: 'header_nav_service' },
  { path: LOCATIONS.HELP, title: 'header_nav_help' },
  { path: LOCATIONS.BLOG, title: 'header_nav_blog' },
  { path: LOCATIONS.CONTACTS, title: 'header_nav_contacts' }
];
