import React from "react";
import {Link} from 'gatsby'
import {useTranslation} from 'react-i18next'

export default (props) => {
    const {i18n} =  useTranslation();
    const defaultLanguage = 'ru';
    if (i18n.language !== defaultLanguage) {
        const customLink = '/'+i18n.language + props.to;
        return <Link {...props} to={customLink} />
    }

    return <Link {...props}/>
}