export const headerPageLinks = [
  { linkTo: "/check-status/", title: "header_nav_transferStatus", isShow: true },
  { linkTo: "/agents/", title: "header_nav_agentLocation", isShow: true },

  { linkTo: "/about/", title: "header_nav_service" , isShow: true },
  { linkTo: "/help/", title: "header_nav_help", isShow: true },
  { linkTo: "/mfaq/", title: "header_nav_mfaq", isShow: false },
  { linkTo: "/blog/", title: "header_nav_blog", isShow: true },
  { linkTo: "/contacts/", title: "header_nav_contacts", isShow: true },
  { linkTo: "/partners/", title: "header_nav_partners", isShow: false }
];

export const footerPageLinks = [
  { linkTo: "/agents/", title: "footer_nav_service", isShow: true },
  { linkTo: "/about/", title: "header_nav_service", isShow: true },
  { linkTo: "/help/", title: "header_nav_help", isShow: true },
  { linkTo: "/blog/", title: "header_nav_blog", isShow: true },
  { linkTo: "/contacts/", title: "header_nav_contacts", isShow: true },
  { linkTo: "/partners/", title: "header_nav_partners", isShow: true }
];
