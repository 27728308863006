import React, { Component } from "react";
import { I18nextProvider, withTranslation } from "react-i18next";
import { graphql, StaticQuery } from "gatsby";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {locales} from '../../config'

export function withTrans(WrappedComponent) {
  WrappedComponent = withTranslation()(WrappedComponent);

  return class extends Component {
    render() {
      return (
        <StaticQuery
          query={graphql`
            query GetTransfers {
              mainContentru {
                content {
                  key
                  value
                }
              }
              mainContenten {
                content {
                  key
                  value
                }
              }
              mainContentde {
                content {
                  key
                  value
                }
              }
            }
          `}
          render={data => {
            let resources = {};
            for(const locale of locales) {
              const translations = data[`mainContent${locale}`].content.reduce(
                  (result, item) => ({ ...result, [item.key]: item.value }),
                  {}
              );
              resources[locale] = { translations }
            }

            i18next.use(LanguageDetector).init({
              fallbackLng: "ru",
              resources,
              ns: ["translations"],
              defaultNS: "translations",
              returnObjects: true,
              debug: false,
              react: {
                wait: true,
                transSupportBasicHtmlNodes: true,
                transKeepBasicHtmlNodesFor: [
                  "br",
                  "strong",
                  "i",
                  "span",
                  "small",
                ],
              },
            });

            i18next.languages = locales;
            return (
              <I18nextProvider i18n={i18next}>
                <WrappedComponent {...this.props} i18next={i18next} />
              </I18nextProvider>
            );
          }}
        />
      );
    }
  };
}
