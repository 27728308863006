export const FORM_FIELDS = {
  SENDING_COUNTRY: 'sendingCountry',
  RECEIVING_COUNTRY: 'receivingCountry',
  RECEIVING_CURRENCY: 'receivingCurrency',
  RECEIVING_AMOUNT: 'receivingAmount',
  SENDING_CURRENCY: 'sendingCurrency',
  SENDING_AMOUNT: 'sendingAmount',
  PAID_NOTIFICATION: 'paidNotification'
} as const;

export const DEFAULT_MIN_AMOUNT = 1;
export const MS_COEFFICIENT = 1000;
